import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UtilsHelper } from '@commons/utils/utils';
import { environment } from '@environment';
import { Profession, ProfessionAlgolia } from '@models/profession/profession';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { SearchParamsObject, SearchResponse } from 'algoliasearch';
import { Observable, from, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizbiiProfessionWebservice {
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);
  readonly #http = inject(HttpClient);

  #getClient() {
    return this.#algoliaClientProvider.getClient(environment.algolia.clientName)!;
  }

  getInterests(): Observable<Record<string, string>> {
    return this.#getOptions('interests.label');
  }

  getDomains(): Observable<Record<string, string>> {
    return this.#getOptions('businessLines.label');
  }

  getStatus(): Observable<Record<string, string>> {
    return this.#getOptions('professionalStatus.label');
  }

  getAccessLevels(): Observable<Record<string, string>> {
    return this.#getOptions('minimumAccessLevel.label');
  }

  readonly #getOptions = (facet: string): Observable<Record<string, string>> => {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForFacets({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiProfessions,
                type: 'facet',
                facet,
                query: '',
              },
            ],
          })
        )
      ),
      map((response) => response.results[0]),
      map((status) =>
        status.facetHits.reduce(
          (acc, { value }) => ({ ...acc, [value]: UtilsHelper.capitalizeFirstLetter(value) }),
          {} satisfies Record<string, string>
        )
      )
    );
  };

  search(
    query: string | null,
    searchParams?: Pick<
      SearchParamsObject,
      'page' | 'hitsPerPage' | 'facetFilters' | 'aroundLatLng' | 'aroundRadius' | 'filters'
    >
  ): Observable<SearchResponse<ProfessionAlgolia>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForHits<ProfessionAlgolia>({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiProfessions,
                query: query ?? '',
                ...searchParams,
              },
            ],
          })
        )
      ),
      map((response) => response.results[0])
    );
  }

  getProfession(professionId: string): Observable<Profession> {
    return this.#http.get<Profession>(`${environment.api.obendyContentHub}/v1/job-cards/${professionId}`).pipe(
      map((profession) => {
        const manTitle = UtilsHelper.capitalizeFirstLetter(profession.manTitle);
        const womanTitle = UtilsHelper.capitalizeFirstLetter(profession.womanTitle);
        return {
          ...profession,
          title: manTitle === womanTitle ? manTitle : `${manTitle} / ${womanTitle}`,
          interests: profession.interests.map((interest) => ({
            ...interest,
            label: UtilsHelper.capitalizeFirstLetter(interest.label),
          })),
        };
      })
    );
  }
}
